import { onMount } from "solid-js";
import { openIntercom } from "~/utils/third_party/intercom";
import { useSearchParams } from "@solidjs/router";

export default function ZeptoSupport() {
  const [searchParams] = useSearchParams();

  onMount(() => {
    const email = searchParams.email;
    const orderId = searchParams.orderId;
    const phoneNumber = searchParams.phoneNumber;

    const intercomData: Record<string, any> = {};

    if (email) {
      intercomData.email = email;
    }

    if (orderId) {
      intercomData.order_id = orderId;
    }

    if (phoneNumber) {
      intercomData.phone_number = phoneNumber;
    }

    Object.keys(intercomData).length > 0
      ? openIntercom(intercomData)
      : openIntercom();
  });

  return <div></div>;
}
